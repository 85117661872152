import Vue from 'vue'

const baseUrl = `${window.location.origin}/api`
const lang = document.documentElement.getAttribute('lang')

const initialData = () => {
  return {
    show: false, 
    cart: localStorage.cart ? JSON.parse(localStorage.cart) : [],
    user: localStorage.user ? JSON.parse(localStorage.user) : {
      name: '',
      street: '',
      city: '',
      zip: '',
      country: '',
      phone: '',
      email: '',
      consent: false,
      currentpageid: ''
      },
    submitResponse: Object
  }
}

const getProducts = () => {
  const target = `${baseUrl}/products/allproducts/?lang=${lang}`
  return new Promise((resolve, reject) => {
    fetch(target)
      .then(res => res.json())
      .then(data => resolve(data.Products))
      .catch(error => reject(error))
  })
}

export const store = Vue.observable({
  ...initialData(),
})

const save = () => {
  // TODO: Save store state in local storage
  // Invoke after mutations occur?
  // Or find a way to add side effects
}

export const mutations = {
  addToCart: productId => {
    // TODO: Check if product exists & if it 
    // does increase count, else create new
    let storedProduct = store.cart.find(p => p.id === productId)
    if (storedProduct) {
      //if (storedProduct.count < 3) storedProduct.count += 1
        console.log("Product already in cart");
        return;
    } else {
      getProducts()
      .then(products => {
        const product = products.find(p => p.ProductID === productId)
        if (product) {
          store.cart.push({
            id: product.ProductID,
            count: 1,
            data: {
              name: product.ProductName,
              href: product.PageUrl,
              code: product.ProductCode,
              amount: product.MaterialAmount,
                serialnumber: product.SerialNumber
            }
          })
        }
      })
      .catch(error => console.error(error))
      }
      store.submitResponse = {};
  },
  changeProductCount: (product, newCount) => {
    product.count = parseInt(newCount)
  },
  removeFromCart: product => {
    store.cart = store.cart.filter(p => p !== product)
  },
  toggleCartDrawer: () => {
      
      store.show = !store.show;
      
  },
}