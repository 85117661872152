import Vue from 'vue';
import App from './App.vue';

window.cart = {};

const root = document.getElementById('cart-app');
const app = new Vue(App);
app.$mount(root);

document.addEventListener('mousedown', function (e) {
    var container = document.getElementById('ah-cart');
    if (!container.contains(e.target)) {
        window.cart.hide();
    }
});

document.getElementById('mobile-cart-btn').addEventListener('click', function () {
    window.cart.show();
});