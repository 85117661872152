var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ah-cart-item" }, [
    _c(
      "a",
      {
        staticClass: "ah-cart-item-label",
        attrs: { href: _vm.item.data.href }
      },
      [_vm._v("\n    " + _vm._s(_vm.item.data.name) + "\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "ah-cart-item-controls" }, [
      _c("div", { staticClass: "ah-cart-item-count" }, [
        _c(
          "select",
          {
            staticClass: "form-control ah-cart-form-select",
            on: {
              change: function($event) {
                return _vm.onChangeProductCount($event)
              }
            }
          },
          _vm._l(_vm.item.data.amount, function(index) {
            return _c(
              "option",
              {
                key: index,
                domProps: { value: index, selected: index == _vm.item.count }
              },
              [_vm._v("\n          " + _vm._s(index) + "\n        ")]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "ah-cart-item-remove",
          attrs: { title: _vm.removeLabel },
          on: { click: _vm.removeFromCart }
        },
        [
          _c("img", {
            attrs: { src: "/Static/icons/AH/trash-can-solid.svg", alt: "" }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }