<template>
    <div>
        <div class="ah-open-cart">
            <button class="ah-cart-btn" @click="show()">
                <img :src="openIconSrc" class="open-icon-blue" alt="Open Cart" />
                <img :src="openIconWhiteSrc" class="open-icon-white" alt="Open Cart" />
                <span v-if="form.cart.length > 0">{{ form.cart.length }}</span>
            </button>
            
        </div>
        <div v-show="state.show" class="ah-cart" id="ah-cart">
            <button class="ah-cart-close-button"
                 @click="show()"><img :src="closeIconSrc" alt="" /></button>
            
            <div class="ah-cart-section">
                <h2>
                    {{ labels.heading }}
                </h2>
                <p class="ah-cart-description">
                    {{ labels.description }}
                </p>
            </div>
            <div class="ah-cart-section">
                <h3>
                    {{ labels.cart.heading }}
                </h3>
                <div class="ah-cart-items" v-show="state.cart.length > 0">
                    <cart-item v-for="(item, i) in state.cart"
                               :key="`item-${i}`"
                               :item="item"
                               :removeLabel="labels.cart.removeLabel" />
                </div>
                <div v-show="state.cart.length == 0">
                    <p>{{ labels.cart.cartEmpty }}</p>
                </div>
            </div>
            <div class="ah-cart-section">
                <form v-on:submit.prevent="submitForm" class="d-flex flex-wrap">
                    <h3>
                        {{ labels.form.heading }}
                    </h3>
                    <div class="mb-3 ah-half left">
                        <label for="firstname" class="form-label ah-cart-input-label">
                            {{ labels.form.firstnameLabel }}
                            <span v-if="labels.form.fieldNameIsRequired.name">*</span>
                        </label>
                        <input :required="labels.form.fieldNameIsRequired.name" type="text" class="form-control" id="firstname" v-model="form.user.firstname" />
                    </div>
                    <div class="mb-3 ah-half">
                        <label for="lastname" class="form-label ah-cart-input-label">
                            {{ labels.form.lastnameLabel }}
                            <span v-if="labels.form.fieldNameIsRequired.name">*</span>
                        </label>
                        <input :required="labels.form.fieldNameIsRequired.name" type="text" class="form-control" id="lastname" v-model="form.user.lastname" />
                    </div>
                    <div class="mb-3">
                        <label for="street" class="form-label ah-cart-input-label">
                            {{ labels.form.streetLabel }}
                            <span v-if="labels.form.fieldNameIsRequired.street">*</span>
                        </label>
                        <input :required="labels.form.fieldNameIsRequired.street" type="text" class="form-control" id="street" v-model="form.user.street" />
                    </div>
                    <div class="mb-3 ah-half left">
                        <label for="city" class="form-label ah-cart-input-label">
                            {{ labels.form.cityLabel }}
                            <span v-if="labels.form.fieldNameIsRequired.city">*</span>
                        </label>
                        <input :required="labels.form.fieldNameIsRequired.city"  type="text" class="form-control" id="city" v-model="form.user.city" />
                    </div>
                    <div class="mb-3 ah-half">
                        <label for="zip" class="form-label ah-cart-input-label">
                            {{ labels.form.zipLabel }}
                            <span v-if="labels.form.fieldNameIsRequired.zip">*</span>
                        </label>
                        <div v-if="labels.form.fieldNameIsRequired.postcodeallownumbersonly">
                            <input v-if="labels.form.fieldNameIsRequired.postcodenumbersmaxlength === 0 || null" :required="labels.form.fieldNameIsRequired.zip" type="number" pattern="^(0|[0-9]*)$" oninput="this.value=this.value.slice(0,this.maxLength)" inputmode="numeric" :maxlength="5" class="form-control" id="zip" v-model="form.user.zip" />
                            <input v-else="labels.form.fieldNameIsRequired.postcodeallownumbersonly" :required="labels.form.fieldNameIsRequired.zip" type="number" oninput="this.value=this.value.slice(0,this.maxLength)" :maxlength="labels.form.fieldNameIsRequired.postcodenumbersmaxlength" class="form-control" id="zip" v-model="form.user.zip" />
                        </div>
                        <div v-if="!labels.form.fieldNameIsRequired.postcodeallownumbersonly">
                            <input :required="labels.form.fieldNameIsRequired.zip" type="text" class="form-control" id="zip" v-model="form.user.zip" />
                        </div>
                            </div>
                        <div class="mb-3">
                            <label for="clinic" class="form-label ah-cart-input-label">
                                {{ labels.form.clinicLabel }}
                                <span v-if="labels.form.fieldNameIsRequired.clinic">*</span>
                            </label>
                            <input :required="labels.form.fieldNameIsRequired.clinic" type="text" class="form-control" id="clinic" v-model="form.user.clinic" />
                        </div>
                        <div class="mb-3">
                            <label for="country" class="form-label ah-cart-input-label">
                                {{ labels.form.countryLabel }}
                                <span v-if="labels.form.fieldNameIsRequired.country">*</span>
                            </label>
                            <input :required="labels.form.fieldNameIsRequired.country" type="text" class="form-control" id="country" v-model="form.user.country" />
                        </div>
                        <div class="mb-3">
                            <label for="phone" class="form-label ah-cart-input-label">
                                {{ labels.form.phoneLabel }}
                                <span v-if="labels.form.fieldNameIsRequired.phone">*</span>
                            </label>
                            <input :required="labels.form.fieldNameIsRequired.phone" type="text" class="form-control" id="phone" v-model="form.user.phone" />
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label ah-cart-input-label">
                                {{ labels.form.emailLabel }}
                                <span v-if="labels.form.fieldNameIsRequired.email">*</span>
                            </label>
                            <input :required="labels.form.fieldNameIsRequired.email" type="email" class="form-control" id="email" v-model="form.user.email" />
                        </div>
                        <div v-if="labels.form.fieldNameIsRequired.consent" class="mb-3 form-check ah-form-consent">
                            <input type="checkbox" class="form-check-input" id="consent" v-model="form.user.consent" />
                            <label class="form-check-label" for="consent">
                                {{ labels.form.consentLabel }}
                            </label>
                        </div>
                        <button class="ah-cart-submit-button" :disabled="!form.user.consent && labels.form.fieldNameIsRequired.consent" type="submit">
                            {{ labels.form.buttonLabel }}
                        </button>
                        <div class="ah-submit-message ah-success-message" v-if="submitResponse.SuccessMessage">{{ submitResponse.SuccessMessage }}</div>
                        <div class="ah-submit-message ah-failure-message" v-if="submitResponse.FailureMessage">{{ submitResponse.FailureMessage }}</div>
                    </form>
                </div>
            </div>
        </div>
    </template>

<script>

      import { mutations, store } from './data/store'
      import CartItem from './CartItem.vue'
      export default {
        name: 'app',
        components: {
          CartItem
        },
        computed: {
          state: function() {
            return store
          },
          labels: function() {
            return window.vueCartData.components['cart-drawer']
          },
          emptyForm: function() {
              this.form.user.firstname = "";
              this.form.user.lastname = "";
              this.form.user.street = "";
              this.form.user.city = "";
              this.form.user.zip = "";
              this.form.user.clinic = "";
              this.form.user.country = "";
              this.form.user.phone = "";
              this.form.user.email = "";
              this.form.user.consent = false;
          }
        },
        data() {
          return {
            openIconSrc: '/Static/icons/AH/shopping-basket-solid.svg',
            openIconWhiteSrc: '/Static/icons/AH/shopping-basket-solid-white.svg',
            closeIconSrc: '/Static/icons/AH/x.svg',
            form: {
              cart: [],
              user: {
                firstname: '',
                lastname: '',
                street: '',
                zip: '',
                clinic: '',
                country: '',
                phone: '',
                email: '',
                consent: false,
                currentpageid: ''
              },
              },
            submitResponse: Object
          }
        },
        watch: {
          state: {
            handler: function (changes) {
              this.form.cart = [...this.state.cart]
              localStorage.cart = JSON.stringify(changes.cart)
              localStorage.user = JSON.stringify(changes.user)
              this.submitResponse = store.submitResponse;
            },
            deep: true,
          }
        },
        created: function() {
          // Expose app methods globally
            window.cart = this;
            this.form.cart = [...this.state.cart];
        },
        methods: {
          addProduct: id => mutations.addToCart(id),
          show: function() {
              //mutations.toggleCartDrawer();
              this.state.show = !this.state.show;
          },
          hide: function() {
              this.state.show = false;
          },
          handleResponse: function (response) {
            // TODO handle errors
            // ... error handler
            // TODO handle success
            // ... success handler
              console.log(response);


              if (!response.SuccessMessage && !response.FailureMessage) {
                  store.submitResponse = { "FailureMessage": "Unknown Error" };
              } else {
                  store.submitResponse = response;
              }

              if (response.SuccessMessage) {
                  this.state.cart = [];
                  this.emptyForm;
              } else {
                  this.form.user.consent = false;
              }



          },
          submitForm: function (event) {
              event.preventDefault()
              const url = `${window.location.origin}/api/products/ordermaterials?lang=` + this.labels.language;
            const products = this.form.cart.reduce((acc, current) => {
              acc.push({

                product: {
                  id: current.id,
                  name: current.data.name,
                  code: current.data.code,
                  serialnumber:current.data.serialnumber
                  },
                  count: current.count
              })
              return acc
            }, []);


            const payload = {
              products,
              user: this.form.user,
            }
              const postPayload = JSON.stringify(payload);
              console.log(postPayload);
              if (products.length > 0) {
                  fetch(url, {
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json'
                      },
                      body: postPayload
                  })
                      .then(response => response.json())
                      .then(res => this.handleResponse(res))
              } else {
                  store.submitResponse = { "FailureMessage": this.labels.cart.cartEmpty };
              }

          },
        }
      }
</script>

    <style lang="scss">
      @import "../scss/_variablesAH.scss";
        
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
      .ah-cart {
        position: fixed;
        top: 0;
        right: 0;
        width: 500px;
        max-width: 100%;
        height: 100%;
        padding: 52px 24px;
        overflow-y: auto;
        box-sizing: border-box;
        background-color: white;
        box-shadow: 0 0 60px 0 rgba(0,35,74,0.25);
        z-index: 9999;

        div, p, h2, label {
                              color: $blue-a !important;
                          }
      }
      .ah-cart-section {
        margin-bottom: 40px;
      }
      .ah-cart-description {
        font-size: 16px;
        line-height: 24px;
      }
      .ah-cart-items {
        border-top: 1px solid #DCDCE5;
      }
      .ah-cart-close-button {
        background: transparent;
        position: absolute;
        top: 0;
        right: 6px;
        cursor: pointer;

        &:hover { background: transparent; }

        img {
                width: 24px;
            }
      }
      .ah-cart h2 {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 12px;
      }
      .ah-cart h3 {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 24px;
        width: 100%;
      }
      .ah-cart-input-label {
        font-size: 16px;
        font-weight: 600;
      }
      .ah-cart input {
        height: 44px
      }

      header.main-header {
          .open-icon-white { display: none; }
      }

      header.main-header.start {
          .open-icon-white { display: block; }
          .open-icon-blue { display: none; }

          &.active {
              .open-icon-white { display: none; }
              .open-icon-blue { display: block; }
          }
      }


        .mb-3 {
            width: 100%;
        }

      .mb-3.ah-half {
          width: 49%;

          &.left { margin-right: 4px; }
        }

      body header.main-header .ah-cart-submit-button {
        width: 100%;
        height: 50px;
        background-color: $blue-a;
        color: $ah-orange !important;
        font-family: $font-main;
        font-size: 18px;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
                    background-color: $ah-orange !important;
                    color: $blue-a !important;

                }

                &:disabled {
                    background-color: lightgrey !important;
                    color: #FFF !important;
                    cursor: not-allowed;
                }

      }
      .ah-form-consent {
        display: flex!important;
        align-items: center;
      }

      .ah-cart-form-select {
          width: 60px;
          padding: 4px;
      }

      .ah-open-cart {
          margin-right: 48px;
          display: flex;
          justify-content: center;
          align-items: stretch;
          height: 100%;

          .ah-cart-btn {
                           position: relative;
                           background: transparent;

                           img {
                                   width: 30px;
                                   height: auto;


                               }



                            span {
                                position: absolute;
                                background: red;
                                display: flex;
                                color: white;
                                width: 20px;
                                height: 20px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                bottom: 26px;
                                left: 12px;
                            }
                       }
      }

        .ah-submit-message {
            margin: 20px 0;
            padding: 8px;
            display: flex;
            border-radius: 4px;
            border: 1px solid;

            &.ah-failure-message

        {
            background: #ff9999;
            color: #800000;
            border-color: #800000;
        }

        &.ah-success-message {

            background: #85e085;
            color: #155115;
            border-color: #155115;
        }

        }

        @media only screen and (max-width: 991px) {
            .ah-open-cart { display: none; }

            header.main-header .cart-container > div {
                justify-content: flex-start;
                margin-left: 30px;
            }

            .ah-cart {
                width: 100%;
            }
        }
    </style>
