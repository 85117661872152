<template>
  <div class="ah-cart-item">
    <a class="ah-cart-item-label" :href="item.data.href">
      {{ item.data.name }}
    </a>
    <div class="ah-cart-item-controls">
      <div class="ah-cart-item-count">
        <select class="form-control ah-cart-form-select" @change="onChangeProductCount($event)">
          <option
            v-for="index in item.data.amount"
            :key="index"
            :value="index"
            :selected="index == item.count"
          >
            {{ index }}
          </option>
        </select>
      </div>
      <button class="ah-cart-item-remove" @click="removeFromCart" :title="removeLabel">
        <img src="/Static/icons/AH/trash-can-solid.svg" alt="" />
      </button>      
    </div>
  </div>
</template>

<script>
import { mutations } from './data/store'
  export default {
    name: 'cart-item',
    props: {
      item: Object,
      removeLabel: String,
    },
    methods: {
      onChangeProductCount: function(event) {
        mutations.changeProductCount(this.item, event.target.value)
      },
      removeFromCart: function() {
        mutations.removeFromCart(this.item)
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ah-cart-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #DCDCE5;
        padding: 12px 0;
        color: #00234A !important;
        .ah-cart-item-label

    {
        font-size: 17px;
        text-decoration: underline;
        padding-right: 20px;
        width: 70%;
        color: #00234A !important;
    }

  }
  
  .ah-cart-item-controls {
    display: flex;
    align-items: center;
  }
  .ah-cart-item-count {
    margin-right: 18px;
  }
  .ah-cart-item-remove {
    cursor: pointer;
    background: transparent;
    padding: 8px;

    img { 
            width: 30px;
        }
  }
</style>